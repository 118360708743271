.contact{
    background-color: rgb(0, 0, 0);
}
.contact a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  .pg-footer {
    font-family: 'Roboto', sans-serif;
  }
  
  
  .footer {
      background-color:#000000;
      color: #fff;
  }
  .footer-wave-svg {
      background-color: transparent;
      display: block;
      height: 30px;
      position: relative;
      top: -1px;
      width: 100%;
  }
  .footer-wave-path {
      fill: #F7F7F7;
  }
  
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 450px;
      position: relative;
  }
  
  .footer-content-column {
      box-sizing: border-box;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      color: #fff;
  }
  
  .footer-content-column ul li a {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  
  .footer-logo-link {
      display: inline-block;
  }
  .footer-logo-link h3{
      color: rgb(255, 255, 255);

  }
  .footer-menu {
      margin-top: 30px;
  }
  
  .footer-menu-name {
      color: #ffffff;
      font-size: 15px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-menu-list {
      list-style: none;
      margin-bottom: 0;
      margin-top: 10px;
      padding-left: 0;
  }
  .footer-menu-list li {
      margin-top: 5px;
  }
  
  .footer-call-to-action-description {
      color: #fffff2;
      margin-top: 10px;
      margin-bottom: 20px;
  }
  .footer-call-to-action-button:hover {
      background-color: #FFE8EC;
      color: #000000;
  }
  .button:last-of-type {
      margin-right: 0;
  }
  .footer-call-to-action-button {
      background-color:#ffffff;
      /* background-color: #027b9a; */
      border-radius: 21px;
      color: #fffff2;
      display: inline-block;
      font-size: 11px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      padding: 12px 30px;
      margin: 0 10px 10px 0;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color .2s;
      cursor: pointer;
      position: relative;
  }
  .footer-call-to-action {
      margin-top: 30px;
  }
  .footer-call-to-action-title {
      color: #fffff2;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-call-to-action-link-wrapper {
      margin-bottom: 0;
      margin-top: 10px;
      color: #fff;
      text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
      color: #fff;
      text-decoration: none;
  }
  
  
  
  
  
  .footer-social-links {
      bottom: 0;
      height: 54px;
      position: absolute;
      right: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-svg {
      height: 54px;
      left: 0;
      display: block;
      position: absolute;
      top: 0;
      width: 236px;
  }
  
  .footer-social-amoeba-path {
      /* background-color:; */
      fill: #F7F7F7;
  }
  
  .footer-social-link.linkedin {
      height: 26px;
      left: 3px;
      top: 11px;
      width: 26px;
  }
  
  .footer-social-link {
      display: block;
      padding: 10px;
      position: absolute;
  }
  
  .hidden-link-text {
      position: absolute;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px,1px,1px,1px);
      -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
      clip-path: inset(0px 0px 99.9% 99.9%);
      overflow: hidden;
      height: 1px;
      width: 1px;
      padding: 0;
      border: 0;
      top: 50%;
  }
  
  .footer-social-icon-svg {
      display: block;
  }
  
  .footer-social-icon-path {
    /* font-size: 12vmax; */
      fill: #39594C;
      transition: fill .2s;
  }
  
  .footer-social-link.linkedin{
    height: 48px;
    left: 2px;
    top: 9px;
    width: 48px;
  }
  .footer-social-link.twitter {
      height: 48px;
      left: 62px;
      top: 3px;
      width: 48px;
  }
  
  .footer-social-link.youtube {
      height: 44px;
      left: 123px;
      top: 12px;
      width: 44px;
  }
  
  .footer-social-link.github {
      height: 54px;
      left: 172px;
      top: 10px;
      width: 54px;
  }
 
  


  
  
  
  
  
  
  /* Media Query For different screens */
  @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  
  }
  @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  
  }
  @media (min-width:1281px) { /* hi-res laptops and desktops */
  
  }
  
  
  
  
  @media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 450px;
        position: relative;
    }
  
    .footer-wave-svg {
        height: 50px;
    }
  
    .footer-content-column {
        width: 24.99%;
    }
  }
  @media (min-width: 568px) {
    /* .footer-content-column {
        width: 49.99%;
    } */
  }
  