.segment{
    position: relative;
    height: 70vh;
    width: 100%;
    background-color: #fff;
    
}

#two{
    background-color: #ffe8ec;
}

#four{
    background-color: #ffe8ec;
}


.segleft{
    height: 40vh;
    width: 40%;
    text-align: left;
     /* background-color: beige; */
}

.segOddLeft{
    position: absolute;
    top: 30%;
    left: 15%;
}

.segEvenLeft{
    position: absolute;
    top: 30%;
    right: 5%;
    /* margin-left: 34vmax;
    margin-top: 6vmax; */
    
}

.segleft h1{
    line-height: 2.9vmax;
    width: 70%;
    font-size: 2.7vmax;
    font-family: 'Josefin Sans', sans-serif;
}

.segleft p{
    width: 65%;
    font-size: 1.1vmax;
font-family: 'Aldrich', sans-serif;
;
}

.segOddright img{
    height: 65vh;
    margin-left: 34vmax;
    margin-top: 6vmax;
}

.segEvenright img{
    position: absolute;
    top: 10%;
    left: 15%;
    height: 65vh;
}

@media (max-width: 500px) {
    

.segEvenLeft {
    position: absolute;
    top: 2%;
    right: 6%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.segleft {
    height: 40vh;
    width: 90%;
    text-align: right;
    /* background-color: beige; */
}

.segleft h1 {
    line-height: 4.9vmax;
    width: 100%;
    font-size: 4vmax;
    font-family: 'Josefin Sans', sans-serif;

}

.segleft p {
    width: 80%;
    font-size: 1.8vmax;
    font-family: 'Aldrich', sans-serif;
    ;
}

.segEvenright img {
    position: absolute;
    top: 42%;
    left: 3%;
    height: 45vh;
}

.segOddLeft{
    top: 5%;
    left: 3%;
    text-align: left;
}

.segOddLeft h1{
    font-size: 3vmax;
}

.segOddright img {
    height: 41vh;
    margin-left: 1vmax;
    margin-top: 30vmax;
}

}