.service_sec1{
    height: 60vh;
    width: 100%;
    background-color: #feeaed;
}


#serviceTextDiv{
    position: absolute;
    top: 20%;
    left: 15%;
    height: 30vh;
    width: 50%;
    text-align: left;
}

#serviceTextDiv h1 {
    width: 40%;
    font-family: ogg;
}

#serviceTextDiv button {
    position: absolute;
    top: 90%;
    left: 0%;
}

#sec1_img{
    position: absolute;
    top: 40%;
    right: 15%;
    transform: translate(0,-50%);
    height: 70vh;
}

.service_sec2{
    height: 80vh;
    width: 100%;
    background-color: #fff;
}

.service_sec2>h1{
    font-family: ogg;
    font-size: 2vmax;
}

.service_card{
    position: absolute;
    left: 5%;
    min-height: 55vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff3f4;
    flex-wrap: wrap;
    padding: 1vmax;
}

.card_service{
    text-align: left;
    padding: 1vmax;
    /* margin-left: 2vmax;
    margin-right: 2vmax; */
    height: 48vh;
    width: 20%;
    background-color: #fff;
}

.card_service .card_service_image{
    height: 20vh;
}

.service_sec3{
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #FCFEFC;
}

.service_box{
    position: relative;
    font-family: ogg;
    height: 40vh;
    width: 100%;

}

#service_gif1{
    position: absolute;
    left: 5%;
    height: 35vh;
}

#service_gif_text1{
    position: absolute;
    left: 25%;
    top: 30%;
}

#service_gif2{
    position: absolute;
    right: 5%;
    height: 35vh;
}

#service_gif_text2{
    position: absolute;
    left: 40%;
    top: 30%;
}

#service_gif_text3{
    position: absolute;
    font-size: 1.5vmax;
    width: 30%;
    left: 40%;
    top: 60%;
}

#service_gif_btn{
    top: 80%;
    left: 48%;
}

@media (max-width: 500px) {

    .service_sec1{
        height: 75vh;
    }


    #serviceTextDiv{

        top: 42%;
        }
        
        #sec1_img {
            position: absolute;
            top: 25%;
            right: 10%;
            transform: translate(0,-50%);
            height: 43vh;
        }

    #serviceTextDiv{

        height: 36vh;
        width: 84%;
        text-align: center;
        left: 10%;
        }
        
        #serviceTextDiv h1{
        
        width: 91%;
        text-align: center;
        }
        
        #serviceTextDiv button{
        
        top: 76%;
        left: 48%;
        transform: translate(-50%,0%);
        }

        
.service_sec2 {
    min-height: 220vh;
    width: 100%;
    position: relative;
}

.service_sec2>h1 {
    font-size: 3vmax;
    margin-top: 3vmax;
}

.service_card {
    left: 3%;
    top: 4%;
    min-height: 55vh;
    width: 90%;
    flex-direction: column;
}

.card_service {
    text-align: center;
    width: 96%;
}

#service_gif1 {
    left: 5%;
    height: 28vh;
    
}

#service_gif_text1 {
    left: 25%;
    top: 64%;
}

#service_gif2 {
    top: 10%;
    height: 25vh;
}

#service_gif_text2 {
    left: 9%;
    top: 65%;
    text-align: left;
    width: 82%;
}

#service_gif_text3 {
    font-size: 1.5vmax;
    width: 84%;
    left: 7%;
    top: 81%;
    text-align: left;
}

#service_gif_btn {
    top: 90%;
    left: 48%;
}
}
