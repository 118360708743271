.segment5{
    position: relative;
    height: 90vh;
    width: 100%;
    background-color: #ffffff;
    font-family: 'Josefin Sans', sans-serif;
}

.segment5 h1{
    position: absolute;
    top: 5%;
    left: 7%;
}

#topBox{
    height: 80vh;
    width: 90%;
    /* background-color: rgb(255, 160, 160); */
    position: absolute;
    top: 9%;
    left: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.brandBox{
    /* padding: 0.5vmax; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vmax;
    margin: 0.5vmax;
    height: 15vmax;
    width: 15vmax;
    /* background-color: rgb(205, 123, 17); */
}

.brandBox:hover{
    color: red;
}


.brandBox img{
    width: 60%;
}

.brandBox:nth-child(9) img{
    width: 190%;
}


@media (max-width: 500px) {
    .segment5{
        height: 95vh;
        }
        
        .segment5 h1 {
            top: 1%;  
        }
        
        #topBox {
        top: 16%;
        }
        
        .brandBox{
        font-size:2.5vmax;
        }

        
.brandBox:nth-child(9) img{
    width: 141%;
}
}