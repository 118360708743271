#Landing{
    /* position: relative; */
    height: 200vh;
    width: 100%;
    background-color: #ffe8ec;
}

#landingCard{
    /* background-color: #fff; */
    height:60vh;
    width: 80%;
    position: absolute;
    top: 19%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, -15%);
}

#landingCard h1{
    margin-bottom: 12vmax;
    line-height: 3.5vmax;
    font-family: 'Josefin Sans', sans-serif;

    font-size: 3.2vmax;
}

#landingCard p{
    position: absolute;
    top: 45%;
    font-family: 'Poiret One', cursive;
    font-weight: 900;

    left:50%;
    transform: translate(-50%,0%);
    font-size: 1.5vmax;
    
}

button{
    position: absolute;
    top: 75%;
    left: 40%;
    width: 14vmax;
    padding: 0.7vmax;
    font-size: 2vmax;
    border-radius: 2vmax;
    background-color: #fdadbc;
    border: none;
}

#Landing>img{
    position: absolute;
    top: 70%;
    left: 50%;
    height: 70vh;
    transform: translate(-50%,0%);
    /* background-color: saddlebrown; */
}

button:hover{
    background-color: #faa1b2;
}

@media (max-width: 500px) {
    
    #Landing{
        height: 176vh;
    }
    
#landingCard{
    width: 100%;
    }
    
    #landingCard h1 {
        margin-bottom: 12vmax;
        line-height: 6.5vmax;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 5.2vmax;
    }
    
    #landingCard p{
        transform: translate(-50%,0%);
        width: 100%;
        top: 53%;
        left: 50%;
        font-size: 2vmax;
    
    }
    
    button{
    
    width: auto;
    top: 75%;
    left: 50%;
    transform:translate(-50%,0%);
    font-size: 4vmax;
    border-radius: 4vmax;
    padding-right: 1.5vmax;
    padding-left: 1.5vmax;
    }

    #Landing>img {
        height: 28vh;
        left: 50%;
        transform: translate(-50%,0%);
    }
}