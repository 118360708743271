#landing2{
    position: absolute;
    top: 141%;
    height: 70vh;
    width: 100%;
    background-color: #fff;
    
}

.left{
    height: 40vh;
    width: 40%;
    /* background-color: beige; */
    position: absolute;
    top: 30%;
    left: 15%;
    text-align: left;
    
}

.left h1{
    line-height: 2.9vmax;
    width: 60%;
    font-size: 2.7vmax;
    font-family: 'Josefin Sans', sans-serif;
}

.left p{
font-family: 'Aldrich', sans-serif;
    width: 65%;
    font-size: 1.1vmax;

}

.right img{
    height: 65vh;
    margin-left: 34vmax;
    margin-top: 6vmax;
}

@media (max-width: 500px) {

    #landing2{
        top: 116%;
    }

    #landing2 .left{
        width: 91%;
        top: 5%;
        left: 8%;
    }

    .left h1{
        font-size: 4vmax;
        width: 80%;
    }

    .left p{
        font-size: 1.6vmax;
    }

    .right img {
        height: 35vh;
        margin-left: 0vmax;
        margin-top: 33vmax;
    }
}